function test(message) {
	console.log(message);
}

// <iframe width="600" height="338" src="https://www.youtube.com/embed/_xjAOW9FSDY" frameborder="0" allowfullscreen></iframe>

var VideoCheck = function() {
	var t = this;

	var iframe = '';

	t.init = function() {
		var video = document.querySelector(".video-container__video");
		t.getUrl(video);
	};

	t.getUrl = function(video) {
		var url = video.getAttribute("data-url");
		var dims = {
			w: 0,
			h: 0
		};
		dims.w = video.getAttribute("data-width");
		dims.h = video.getAttribute("data-height");
		t.buildIframe(url, dims);
	};

	t.buildIframe = function(url, dims) {
		var iframeMarkup =
			'<iframe width="' +
			dims.w +
			'" height="' +
			dims.h +
			'" src="' +
			url +
			'" frameborder="0" allowfullscreen></iframe>';
			iframe = iframeMarkup
	};

	t.renderVideo = function() {
		var oldVid = document.querySelector(".video-container__video");
		var vidCont = document.querySelector(".video-container");
		if (iframe !== '') {
			oldVid.parentNode.removeChild(oldVid);
			vidCont.innerHTML = iframe;
		}
	};
};

jQuery(document).ready(function($) {
	var vidC = new VideoCheck();
	var toggle = document.querySelector('#button-discover');
	vidC.init();
	toggle.addEventListener('click', function () {
		vidC.renderVideo();
	});
});
